<template>
  <div>
    <span class="viewH">Категории</span>
    <hr>
    <div class="cost-content">

      <div class="cost" v-if="!visEdit">
        <div class="cost-item"
          v-for="(item, i) in getCost" 
          :key="i"
          @click="costEdit(item.id)"
        >{{item.cat}}</div>
      </div>

      <div class="cost-edit" v-if="visEdit">

        <v-text-field
          v-model="edit.cat"
          label="Категория"
          dense
          outlined
        ></v-text-field>

        <v-text-field
          v-model="edit.term"
          label="Срок обучения"
          dense
          outlined
        ></v-text-field>

        <v-textarea
          v-model="edit.description"
          label="Описание категории"
          auto-grow
          outlined
        ></v-textarea>

        <div class="cost-block">

          <div class="cost-block-item">
            <div class="cost-edit-item">
              <v-switch
                v-model="edit.pV"
                label="от"
              ></v-switch>

              <v-text-field
                v-model="edit.cost_v"
                label="Стоимость Витебск"
                dense
                outlined
                type="number"
              ></v-text-field>
            </div>

            <v-textarea
              v-model="edit.car_v"
              label="Транспорт Витебск"
              no-resize
              outlined
            ></v-textarea>
          </div>

          <div class="cost-block-item">
            <div class="cost-edit-item">
              <v-switch
                v-model="edit.pO"
                label="от"
              ></v-switch>

              <v-text-field
                v-model="edit.cost_o"
                label="Стоимость Орша"
                dense
                outlined
                type="number"
              ></v-text-field>
            </div>

            <v-textarea
              v-model="edit.car_o"
              label="Транспорт Орша"
              no-resize
              outlined
            ></v-textarea>
          </div>

        </div>

        <div class="cost-edit-btn">
          <v-btn @click="cancel()" small class="btn" color="red">отмена</v-btn>
          <v-btn @click="save()" small class="btn" color="green">сохранить</v-btn>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import {mapGetters, mapActions} from 'vuex'
  export default {
    props: {
      messageOn: Function,
      loadingWindow: Function
    },
    data: () => ({
      visEdit: false,
      edit: {}
    }),
    computed: {
      ...mapGetters(['getCost'])
    },
    methods: {
      ...mapActions(['updCost']),
      costEdit(id) {
        let arr = this.getCost.find(c => c.id == id)
        this.edit.id = arr.id
        this.edit.cat = arr.cat
        this.edit.term = arr.term
        this.edit.description = arr.description
        this.edit.cost_v = arr.cost_v
        this.edit.pV = arr.pV
        this.edit.cost_o = arr.cost_o
        this.edit.pO = arr.pO
        this.edit.car_v = arr.car_v
        this.edit.car_o = arr.car_o
        this.visEdit = true
      },
      cancel() {
        this.edit = {}
        this.visEdit = false
      },
      async save() {
        this.loadingWindow(true)
        let response = await this.updCost(this.edit)
        if (response == 'ok') {
          this.loadingWindow(false)
          this.messageOn('Запись сохранена', 'green')
          this.visEdit = false
        } else {
          this.loadingWindow(false)
          this.messageOn('Ошибка сохранения', 'red')
        }
      }
    },
  }
</script>

<style lang="scss" scoped>
.cost-content {
  margin: 25px 0 0 0;
}
  .cost {
    .cost-item {
      margin: 10px 0 0 0;
      padding: 5px;
      max-width: 500px;
      background-color: #696969;
      cursor: pointer;
      border-radius: 3px;
      transition: all .3s;
      &:hover {
        background-color: #4caf50;
      }
    }
  }
  .cost-edit {
    .cost-block {
      display: flex;
      flex-wrap: wrap;
      gap: 30px;
      flex-direction: row;
    }
    .v-input {
      max-width: 500px;
    }
    .cost-edit-item {
      display: flex;
      align-items: center;
      max-width: 500px;
      .v-input--switch {
        margin: 0 7px 3px 0;
        padding: 0;
      }
    }
    .cost-edit-btn {
      .btn {
        margin-right: 7px;
      }
    }
  }
</style>